var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-100" }, [
    _vm.currentRoute === "tags"
      ? _c(
          "div",
          {
            staticClass:
              "w-100 bg-adori-light-gray white mb2 br2 ba b--adori-gray",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex flex-wrap items-center justify-between ph4 pv3 bb b--adori-gray",
              },
              [
                _c("div", { staticClass: "w-100 w-auto-ns" }, [
                  _vm.hasSelectedTags
                    ? _c("span", { staticClass: "dib f3 fw1 mr2 light-gray" }, [
                        _vm._v(
                          _vm._s(_vm.selectedTagsCount) + " tags selected"
                        ),
                      ])
                    : _c("span", { staticClass: "dib f3 fw1 mr2 light-gray" }, [
                        _vm._v("Tag collections"),
                      ]),
                ]),
                _vm.currentCollection === "ALL_TAGS"
                  ? _c("div", { staticClass: "flex relative" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tagSearchValue,
                            expression: "tagSearchValue",
                          },
                        ],
                        ref: "_searchTags",
                        staticClass:
                          "bg-adori-gray bw0 br2 pl3 pr5 pv3 f6 white",
                        staticStyle: {
                          "margin-bottom": "10px",
                          border: "1px solid #3f3f3f",
                          width: "67%",
                        },
                        attrs: { autofocus: "", placeholder: "Search tags" },
                        domProps: { value: _vm.tagSearchValue },
                        on: {
                          keyup: function ($event) {
                            return _vm.debouncedSearch()
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.tagSearchValue = $event.target.value
                          },
                        },
                      }),
                      _vm.tagSearchValue.length === 0
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons silver absolute",
                              staticStyle: { top: "13px", right: "10px" },
                            },
                            [_vm._v("search")]
                          )
                        : _c(
                            "i",
                            {
                              staticClass:
                                "material-icons silver absolute pointer dim",
                              staticStyle: { top: "13px", right: "10px" },
                              on: { click: _vm.clearSearch },
                            },
                            [_vm._v("close")]
                          ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
    _c("div", { staticClass: "w-100 white br2" }, [
      _vm.hasSelectedTags
        ? _c(
            "div",
            { staticClass: "flex flex-wrap justify-left mv3 items-center" },
            [
              _c(
                "div",
                {
                  staticClass: "pointer",
                  attrs: { title: "Clear selection" },
                  on: { click: _vm.unselectAllTags },
                },
                [_vm._m(0)]
              ),
              _c("BaseButtonBorder", {
                staticClass: "mr2 pv1",
                attrs: {
                  icon: "library_add",
                  text: "Add to collection",
                  onClick: _vm.addToCollectionPrompt,
                },
              }),
              _c("BaseButtonBorder", {
                class: _vm.currentCollection === "ALL_TAGS" ? "dn" : "mr2 pv1",
                attrs: {
                  icon: "library_books",
                  text: "Move to collection",
                  onClick: _vm.moveToCollectionPrompt,
                },
              }),
              _c("BaseButtonRed", {
                staticClass: "mr2 pv1",
                attrs: {
                  icon: "delete",
                  text: "Delete selected tags",
                  onClick: _vm.deleteSelectedTagsPrompt,
                },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              { staticClass: "flex flex-wrap justify-left items-center" },
              [
                _vm.showCreateCollection && _vm.$permissions.isEditTagAllowed()
                  ? _c(
                      "div",
                      {
                        staticClass: "pointer",
                        attrs: { title: "Create a new collection" },
                        on: { click: _vm.createEmptyCollection },
                      },
                      [_vm._m(1)]
                    )
                  : _vm._e(),
                _vm.showTagsInTrack && _vm.hasTagsInTrack
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-around items-center darker-hover link pointer ph1 pv1-5 br2 ba mr2 mb2 mt2",
                        class:
                          _vm.currentCollection === "TAGS_IN_TRACK"
                            ? "bg-black-30 b--adori-red"
                            : "b--adori-gray",
                        on: {
                          click: function () {
                            return _vm.selectCollection("TAGS_IN_TRACK")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "light-gray f7 mh1" }, [
                          _vm._v("Tags in this track "),
                        ]),
                        _c("span", { staticClass: "light-gray f7 mh1" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.filteredTagsInTrack &&
                                  _vm.filteredTagsInTrack.length
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm.showAllTags
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-wrap justify-around items-center darker-hover link pointer ph2 pv1-5 br2 ba mr2 mb2 mt2",
                        class:
                          _vm.currentCollection === "ALL_TAGS"
                            ? "bg-black-30 b--adori-red"
                            : "b--adori-gray",
                        on: {
                          click: function () {
                            return _vm.selectCollection("ALL_TAGS")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "light-gray f7 mh1" }, [
                          _vm._v("All my tags"),
                        ]),
                        _c("span", { staticClass: "light-gray f7 mh1" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.allTagsCount) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._l(Object.keys(_vm.tagCollections), function (collection) {
                  return _c(
                    "div",
                    {
                      key: collection,
                      staticClass:
                        "flex flex-wrap justify-around items-center darker-hover link pointer br2 ba mr2",
                      class:
                        _vm.currentCollection === collection
                          ? _vm.$permissions.isEditTagAllowed()
                            ? "bg-black-30 b--adori-red"
                            : "bg-black-30 b--adori-red pv3"
                          : _vm.$permissions.isEditTagAllowed()
                          ? "b--adori-gray"
                          : "b--adori-gray pv3",
                      on: {
                        click: function () {
                          return _vm.selectCollection(collection)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "light-gray f7 mh2" }, [
                        _vm._v(_vm._s(collection)),
                      ]),
                      _c("span", { staticClass: "light-gray f7 ml1" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.tagCollections[collection].length) +
                            "\n          "
                        ),
                      ]),
                      _vm.$permissions.isEditTagAllowed()
                        ? _c("BasePopupMenu", {
                            staticClass: "pv1",
                            attrs: {
                              icon: "more_vert",
                              iconSize: 4,
                              items: _vm.collectionMenu(collection),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
            _c("div", { staticClass: "flex items-center" }, [
              _c("div", { staticClass: "flex justify-end mv2 pos" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.orientation,
                        expression: "orientation",
                      },
                    ],
                    staticClass:
                      "bg-adori-light-gray white f6 ph3 br2 h2 ba b--gray",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.orientation = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {},
                      ],
                    },
                  },
                  _vm._l(_vm.filterOptions, function (item, i) {
                    return _c("option", { key: i, domProps: { value: item } }, [
                      _vm._v(
                        "\n              " + _vm._s(item) + "\n            "
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "light-gray" }, [
      _c("i", { staticClass: "material-icons adori-red f3 mr2 dim" }, [
        _vm._v(" cancel "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "light-gray" }, [
      _c("i", { staticClass: "material-icons adori-red f3 mr2 dim" }, [
        _vm._v("add_circle"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }