var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed flex justify-center items-center left-0 right-0 top-0 bottom-0 bg-black-80 no-select z-5",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-column bg-adori-gray ph2 pv3 mh-95 min-w5 br2 shadow-5",
        },
        [
          _c(
            "header",
            { staticClass: "flex flex-row justify-end ph2 pv2" },
            [
              _vm._t("header"),
              _c(
                "button",
                {
                  staticClass:
                    "bw0 bg-adori-gray light-gray link pointer dim right",
                  on: {
                    click: function ($event) {
                      return _vm.handleCloseButtonClicked()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons f2" }, [
                    _vm._v("close"),
                  ]),
                ]
              ),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "w-100 flex justify-center" },
            [
              _c("BaseLabel", {
                staticClass: "mb1 f3",
                attrs: {
                  for: "duration",
                  text: "Enter in HH:MM:SS.SSS format",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex justify-center w-100" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mills,
                  expression: "mills",
                },
              ],
              staticClass:
                "db bg-adori-very-light-gray bw0 br2 ph3 pv2 gray f2 white w-80",
              style: !_vm.isValidationForInput
                ? "border: 1px solid red;"
                : "border: 1px solid transparent;",
              attrs: {
                id: "tagEditTextArea2",
                placeholder: "Enter Duration",
                maxlength: "200",
              },
              domProps: { value: _vm.mills },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.mills = $event.target.value
                },
              },
            }),
          ]),
          _c(
            "footer",
            { staticClass: "flex justify-end ph1 mb2" },
            [
              _c("BaseButtonRed", {
                staticClass: "mt4 f2",
                attrs: { text: "Update", onClick: _vm.addDuration },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }