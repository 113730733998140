


















































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
// components
import EditDurationModal from '@/components/Tags/Modals/EditDurationModal.vue'

// utils
import { toReadableDate, msToSeconds } from '@/utils/time'
import { fontStyles, getTagType } from '@/utils/tag'
import { TagProvider, Title } from '@/components/Tags/tags'

import display_png from '@/assets/display.png'
import video_png from '@/assets/video.png'

@Component({ components: { EditDurationModal } })
export default class AdoriTag extends Vue {
  @Prop(String) tagId!: string
  @Prop() tagInTrack!: any
  @Prop(String) filter!: string
  @Prop(String) audioUid!: string
  @Prop(Number) offsetMillis!: number
  @Prop(Number) millis!: number
  @Prop(Number) duration!: number
  @Prop(Number) index!: number
  @Prop() tagData!: any
  @Prop(Boolean) selectable!: boolean
  @Prop(Boolean) draggable!: boolean
  @Prop(Boolean) hasButtons!: boolean
  @Prop(Boolean) hasCreatedOn!: boolean
  @Prop(Boolean) showPollResult!: boolean
  @Prop(Boolean) ismiliallowed!: boolean
  @Prop(Function)
  updateMillis!: (millis: string, tagId: string, index: number) => void
  @Prop({ default: false })
  smallTag!: boolean

  tagAreaHeight = 33

  updatedMillis = ''
  showDurationModal: boolean = false

  async created() {
    if (!this.tag) await this.$store.dispatch('getTag', this.tagId)
  }

  get captionAreaHeight() {
    if (this.filter === 'SQUARE') return 140
    if (this.filter === 'LANDSCAPE') return 110
    else return 160
  }

  get fontClass() {
    let style
    if (this.tagInTrack && this.tagInTrack.style) style = this.tagInTrack.style
    style = this.tag.style

    const index = (style.fontStyle === 0 ? 1 : style.fontStyle) - 1
    if (this.tagType === 'message') return fontStyles[index] + ' f-12px lh-copy'
    return fontStyles[index] + ' f-8px lh-copy'
  }

  get tag() {
    if (this.tagData) return this.tagData
    return this.tagInTrack
      ? this.standardizeTagSchema(this.tagInTrack)
      : this.$store.getters.tag(this.tagId)
      ? this.standardizeTagSchema(this.$store.getters.tag(this.tagId))
      : this.standardizeTagSchema(this.$store.getters.allTagsById[this.tagId])
  }

  get tagType() {
    return getTagType(this.tag)
  }

  get promo() {
    return TagProvider.PROMO
  }

  get programmatic() {
    return TagProvider.PROGRAMMATIC
  }

  get promHead() {
    return Title.titleProm
  }

  get progHead() {
    return Title.titleProg
  }

  get createdOn() {
    if (this.tagInTrack) return toReadableDate(this.tagInTrack.createdOn)
    return toReadableDate(this.tag.createdOn)
  }

  get convertToHms() {
    return msToSeconds(this.millis)
  }

  get provider() {
    if (this.tagInTrack)
      return this.tagInTrack && this.tagInTrack.campaigns.length > 0 ? this.tagInTrack.campaigns[0].provider.kind : ''
    return this.tag && this.tag.campaigns.length > 0 ? this.tag.campaigns[0].provider.kind : ''
  }

  get src() {
    if (this.tagInTrack)
      return this.tagInTrack && this.tagInTrack.imageInfo
        ? this.tagInTrack.imageInfo.urls.thumb
        : this.tagInTrack.image
        ? this.tagInTrack.image.urls.thumb
        : this.tagInTrack.displayAd
        ? display_png
        : ''

    if (this.smallTag) return this.tag && this.tag.image ? this.tag.image.urls.thumb : ''
    if (this.tag && this.tag.displayAd) return display_png
    if (this.tag && this.tag.videoAd) return video_png
    if (this.tag) return this.tag && this.tag.image ? this.tag.image.urls.thumb : ''
  }

  get showImage() {
    if (this.tagInTrack && this.tagInTrack.imageInfo && this.tagInTrack.imageInfo.url) return true
    if (this.smallTag && this.tag && this.tag.image && this.tag.image.thumbnailURL) return true
    if (this.tag && this.tag.image && this.tag.image.url) return true
    if (this.tag && this.tag.displayAd) return true
    if (this.tag && this.tag.videoAd) return true
    if (this.tag && this.tagType === 'audio') return true

    return false
  }

  get selectionIcon() {
    return this.$store.getters.isTagSelected(this.tagId) ? 'check_circle' : 'radio_button_unchecked'
  }

  get selectionStyle() {
    return this.$store.getters.isTagSelected(this.tagId) ? 'adori-red' : 'o-20'
  }

  get isSuggestedTag() {
    if (this.tagInTrack && this.tagInTrack.make) return this.tagInTrack.make === 'SUGGESTED'
    if (this.tag && this.tag.make) {
      return this.tag.make === 'SUGGESTED'
    }
    return false
  }

  get dur() {
    return this.duration
  }

  pollVoteCount() {
    if (this.tagInTrack && this.tagInTrack.pollResult)
      return Object.values(this.tagInTrack.pollResult).reduce((a: any, b: any) => a + b, 0)

    if (this.tag.pollResult) {
      return Object.values(this.tag.pollResult).reduce((a: any, b: any) => a + b, 0)
    }
  }

  openDurationModal() {
    this.showDurationModal = !this.showDurationModal
    this.$emit('modalEdit')
  }

  setMillis(millis: string) {
    this.updatedMillis = millis
  }

  saveDuration() {
    this.updateMillis(this.updatedMillis, this.tag.id, this.index)
    this.openDurationModal()
  }

  standardizeTagSchema(tag: any) {
    // All tag styles are under tag.style object
    delete tag.fontStyle
    delete tag.imageOpacity
    delete tag.topMarginPercentage

    // Image
    if (tag.imageInfo) {
      tag.image = tag.imageInfo
      delete tag.imageInfo
    }

    // Location
    if (tag.locationInfo) {
      tag.location = tag.locationInfo
      delete tag.locationInfo
    }

    // Poll
    if (tag.choices) {
      tag.poll = tag.choices
      delete tag.choices
    }

    return tag
  }
}
