var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showImage
        ? _c(
            "BaseAdoriTagImage",
            {
              staticClass: "shadow-5 hide-child relative",
              class: {
                "grow grow-small": _vm.draggable,
                ismiliallowed: _vm.ismiliallowed,
                programatic: _vm.tag && _vm.tagType === "audio",
              },
              attrs: {
                src: _vm.src,
                title: _vm.isSuggestedTag ? "Suggested" : "",
                filter: _vm.filter,
              },
            },
            [
              _vm.tag
                ? _c(
                    "div",
                    { staticClass: "w-100 h-100" },
                    [
                      _vm.tagType === "audio"
                        ? _c("div", { staticClass: "w-100 h-100" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "h-100 w-100 flex flex-column justify-center items-center pb1 prog-color",
                              },
                              [
                                _c("p", { staticClass: "tc small" }, [
                                  _vm._v(_vm._s(_vm.tag.caption)),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "normal ba pa2 br2 ultra-small b--green",
                                  },
                                  [_vm._v("Audio Slot")]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm.tag.buy
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "w-100 h-100 flex items-center justify-center",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "bg-red white br2 relative pv2 ph2 margin-7 f7 center",
                                  staticStyle: { width: "calc(100% - 64px)" },
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.tag.buy.actionText) +
                                      "\n        "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.tag.caption || _vm.tag.notetext
                        ? _c(
                            "div",
                            {
                              staticClass: "relative w-100",
                              style: { height: _vm.captionAreaHeight + "px" },
                            },
                            [
                              _c("BaseAdoriTagCaption", {
                                attrs: {
                                  caption: _vm.tag.caption || _vm.tag.notetext,
                                  fontClass: _vm.fontClass,
                                  topMargin: _vm.tag.style.topMarginPercentage,
                                  centered: _vm.tagType === "message",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.tag.poll
                        ? _c(
                            "div",
                            {
                              staticClass: "relative w-100",
                              style: { height: _vm.captionAreaHeight + "px" },
                            },
                            _vm._l(
                              [
                                "topic",
                                "choice1",
                                "choice2",
                                "choice3",
                                "choice4",
                              ],
                              function (ch, i) {
                                return _c(
                                  "BaseAdoriTagCaption",
                                  {
                                    key: i,
                                    staticClass:
                                      "ba br2 b--adori-gray ml2 lh-copy f-8px tl",
                                    class: i != 0 ? "truncate" : "",
                                    staticStyle: { width: "calc(100% - 16px)" },
                                    attrs: {
                                      caption: _vm.tag.poll[ch],
                                      topMargin: 0.05 + 0.16 * i + 0.05 * !!i,
                                    },
                                  },
                                  [
                                    _vm.showPollResult &&
                                    i >= 1 &&
                                    _vm.tag.pollResult[ch] &&
                                    _vm.tag.pollResult[ch] !== 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "absolute pa1 top-0 left-0 bg-adori-red tr o-80 h1",
                                            style: {
                                              width:
                                                (_vm.tag.pollResult[ch] /
                                                  _vm.pollVoteCount()) *
                                                  100 +
                                                "%",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr1 b" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.tag.pollResult[
                                                      ch
                                                    ].toLocaleString("en-US")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                      _vm.hasButtons
                        ? _c("BaseAdoriTagButtons", {
                            attrs: {
                              tagType: _vm.tagType,
                              shareable: _vm.tag.shareable,
                              saveable: _vm.tag.saveable,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm.tag && _vm.tag.videoId
        ? _c(
            "BaseVideoPlayer",
            {
              staticClass: "shadow-5 hide-child relative",
              class: {
                "grow grow-small": _vm.draggable,
                ismiliallowed: _vm.ismiliallowed,
              },
              attrs: {
                tagId: _vm.tagId,
                src:
                  (!!_vm.tag.video.mp4Urls &&
                    _vm.tag.video.mp4Urls.thumb.url) ||
                  _vm.tag.video.originalUrl,
                filter: _vm.filter,
                noControls: "",
              },
            },
            [
              _c(
                "div",
                { staticClass: "w-100 h-100" },
                [
                  _vm.tag.buy
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-100 h-100 flex items-center justify-center",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "bg-red white br2 relative pv2 ph2 margin-7 f7 center",
                              staticStyle: { width: "calc(100% - 64px)" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.tag.buy.actionText) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.tag.caption || _vm.tag.notetext
                    ? _c(
                        "div",
                        {
                          staticClass: "relative w-100",
                          style: { height: _vm.captionAreaHeight + "px" },
                        },
                        [
                          _c("BaseAdoriTagCaption", {
                            attrs: {
                              caption: _vm.tag.caption || _vm.tag.notetext,
                              fontClass: _vm.fontClass,
                              topMargin: _vm.tag.style.topMarginPercentage,
                              centered: _vm.tagType === "message",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.tag.poll
                    ? _c(
                        "div",
                        {
                          staticClass: "relative w-100",
                          style: { height: _vm.captionAreaHeight + "px" },
                        },
                        _vm._l(
                          ["topic", "choice1", "choice2", "choice3", "choice4"],
                          function (ch, i) {
                            return _c(
                              "BaseAdoriTagCaption",
                              {
                                key: i,
                                staticClass:
                                  "ba br2 b--adori-gray ml2 lh-copy f-8px tl",
                                class: i != 0 ? "truncate" : "",
                                staticStyle: { width: "calc(100% - 16px)" },
                                attrs: {
                                  caption: _vm.tag.poll[ch],
                                  topMargin: 0.05 + 0.16 * i + 0.05 * !!i,
                                },
                              },
                              [
                                _vm.showPollResult &&
                                i >= 1 &&
                                _vm.tag.pollResult[ch] &&
                                _vm.tag.pollResult[ch] !== 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "absolute pa1 top-0 left-0 bg-adori-red tr o-80 h1",
                                        style: {
                                          width:
                                            (_vm.tag.pollResult[ch] /
                                              _vm.pollVoteCount()) *
                                              100 +
                                            "%",
                                        },
                                      },
                                      [
                                        _c("span", { staticClass: "mr1 b" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.tag.pollResult[
                                                ch
                                              ].toLocaleString("en-US")
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }
                        ),
                        1
                      )
                    : _vm._e(),
                  _vm.hasButtons
                    ? _c("BaseAdoriTagButtons", {
                        attrs: {
                          tagType: _vm.tagType,
                          shareable: _vm.tag.shareable,
                          saveable: _vm.tag.saveable,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.millis && _vm.tag && _vm.tagType === "audio"
        ? _c(
            "div",
            {
              staticClass: "absolute top-millis small pointer bck-green",
              on: { click: _vm.openDurationModal },
            },
            [
              _c(
                "i",
                {
                  staticClass:
                    "material-icons f2 absolute right-15 top-minus25",
                },
                [_vm._v("edit")]
              ),
              _vm._v("\n    " + _vm._s(_vm.convertToHms) + "\n  "),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "absolute top-0 left-0" }, [
        _vm.selectable && _vm.$permissions.isEditTagAllowed()
          ? _c(
              "i",
              {
                staticClass:
                  "material-icons f2 white mt1 ml1 relative text-shadow",
                class: _vm.selectionStyle + " pointer",
                on: {
                  mousedown: function ($event) {
                    return _vm.$store.dispatch("toggleSelectTag", _vm.tagId)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.selectionIcon))]
            )
          : _vm._e(),
      ]),
      _vm.tag && _vm.hasCreatedOn
        ? _c("div", { staticClass: "mt1 gray tc" }, [
            _c("i", { staticClass: "material-icons v-mid f5 mr2" }, [
              _vm._v("date_range"),
            ]),
            _c("span", { staticClass: "f6" }, [_vm._v(_vm._s(_vm.createdOn))]),
          ])
        : _vm._e(),
      _vm.isSuggestedTag
        ? _c(
            "div",
            {
              staticClass: "light-gray absolute f7 bg-adori-gray pa1 br2",
              staticStyle: {},
            },
            [_vm._v("\n    Suggested\n  ")]
          )
        : _vm._e(),
      _vm.showDurationModal
        ? _c("EditDurationModal", {
            attrs: {
              millis: _vm.convertToHms,
              saveDuration: _vm.saveDuration,
              openDurationModal: _vm.openDurationModal,
            },
            on: { setMillis: _vm.setMillis },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }