

































































































































import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import debounce from 'lodash.debounce'

import { tagQueryKeys, useDeleteTag } from '@/hooks/tag'
import Invalidate from '@/mixins/Invalidate'

@Component({
  components: {},
  setup() {
    const { mutateAsync: delTag, isLoading: isDelTagLoading } = useDeleteTag()

    return {
      delTag,
      isDelTagLoading,
    }
  },
})
export default class TheTagCollections extends Mixins(Invalidate) {
  @Prop(String) currentCollection!: string
  @Prop(String) filter!: string
  @Prop(Function) selectCollection!: (name: string) => void
  @Prop(Boolean) showCreateCollection!: boolean
  @Prop(Boolean) showTagsInTrack!: boolean
  @Prop(Boolean) showAllTags!: boolean
  @Prop(Boolean) showTagSuggestions!: boolean
  @Prop(Array) tagsInTrack!: object[]

  @Getter networkId!: any
  @Getter networkType!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any

  @Action setIsTagSearching!: any

  @Action setTagSearchValue!: any
  @Action clearTagSearch!: any
  @Action getTagIds!: any

  tagSearchValue: string = ''
  filterOptions: any = ['LANDSCAPE', 'SQUARE', 'PORTRAIT']

  delTag!: any

  get orientation() {
    return this.filter
  }

  set orientation(value: string) {
    this.$emit('setFilter', value)
  }

  clearSearch() {
    this.tagSearchValue = ''
    this.onTagSearchValueChanged()
  }

  // @Watch('tagSearchValue')
  onTagSearchValueChanged() {
    this.setIsTagSearching(true)

    this.setTagSearchValue(this.tagSearchValue)
  }

  debouncedSearch = debounce(() => {
    this.onTagSearchValueChanged()
  }, 600)

  @Watch('currentCollection')
  handleCollectionChange(newVal: any, oldVal: any) {
    if (oldVal === 'ALL_TAGS' && this.tagSearchValue) {
      this.clearSearch()
    }
  }

  beforeDestroy() {
    this.$store.dispatch('unselectAllTags')
    // this.clearTagSearch()
    if (this.tagSearchValue) {
      this.clearSearch()
      this.getTagIds({ limit: 50, query: this.tagSearchValue })
    }
    this.setIsTagSearching(false)
  }

  get filteredTagsInTrack() {
    return this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]
      ? this.tagsInTrack.filter((tag: any) => {
          const offsetMillis = tag.offsetMillis / 1000
          if (
            offsetMillis >= this.selectedEpisodeSettings[this.ytModalId].startTimeSec &&
            offsetMillis <= this.selectedEpisodeSettings[this.ytModalId].endTimeSec
          )
            return tag
        })
      : this.tagsInTrack
  }

  get tagCollections() {
    return this.$store.getters.tagCollections
  }

  get allTagsCount() {
    return this.$store.getters.tagIdsCount !== -1 ? this.$store.getters.tagIdsCount : 0
  }

  get hasTagsInTrack() {
    return this.tagsInTrack !== undefined
  }

  get selectedTags() {
    return this.$store.getters.selectedTags
  }

  get selectedTagsCount() {
    return this.selectedTags.length
  }

  get hasSelectedTags() {
    return this.selectedTagsCount !== 0
  }

  get currentRoute() {
    return this.$router.currentRoute.name
  }

  collectionMenu(collectionName: any) {
    return [
      {
        name: 'Rename',
        icon: 'edit',
        onClick: () => this.renameCollection(collectionName),
      },
      {
        name: 'Delete',
        icon: 'delete',
        onClick: () => this.deleteCollection(collectionName),
      },
    ]
  }

  createEmptyCollection() {
    this.$store.dispatch('showConfirm', {
      title: 'Create a new collection',
      inputPlaceholder: 'Enter collection name',
      yesButtonText: 'Save',
      noButtonText: 'Cancel',
      error: '',
      onConfirm: () => {
        const collectionName = this.$store.state.modal.confirm.inputValue.trim()
        if (!collectionName) {
          this.$store.dispatch('setConfirmError', 'Collection name is empty.')
        } else if (Object.keys(this.tagCollections).includes(collectionName)) {
          this.$store.dispatch('setConfirmError', 'Collection already exists.')
        } else {
          this.$store.dispatch('addTagCollection', {
            collectionName,
            tagIds: [],
          })
          this.$store.dispatch('closeModal')
        }
      },
    })
  }

  deleteCollection(collectionName: string) {
    this.$store.dispatch('showConfirm', {
      title: 'Delete tag collection?',
      description: 'You are about to delete this tag collection. The tags inside will be preserved.',
      yesButtonText: 'Yes, delete this collection',
      noButtonText: 'No, keep this collection',
      onConfirm: () => {
        this.$store.dispatch('removeTagCollection', {
          collectionName,
        })
        this.$store.dispatch('closeModal')
        this.selectCollection('ALL_TAGS')
      },
    })
  }

  renameCollection(oldCollectionName: string) {
    this.$store.dispatch('showConfirm', {
      title: 'Rename collection',
      inputPlaceholder: 'Enter collection name',
      yesButtonText: 'Save',
      noButtonText: 'Cancel',
      error: '',
      inputValue: oldCollectionName.trim(),
      onConfirm: () => {
        const newCollectionName = this.$store.state.modal.confirm.inputValue.trim()
        if (!newCollectionName) {
          this.$store.dispatch('setConfirmError', 'Collection name is empty.')
        } else if (Object.keys(this.tagCollections).includes(newCollectionName)) {
          this.$store.dispatch('setConfirmError', 'Collection already exists.')
        } else {
          this.$store.dispatch('renameTagCollection', {
            oldCollectionName,
            newCollectionName,
          })
          this.$store.dispatch('closeModal')
          this.selectCollection(newCollectionName)
        }
      },
    })
  }

  unselectAllTags() {
    this.$store.dispatch('unselectAllTags')
  }

  addToCollectionPrompt() {
    this.$store.dispatch('showConfirm', {
      title: 'Add to collection',
      options: Object.keys(this.$store.getters.tagCollections)
        .filter((c) => c !== this.currentCollection)
        .map((c) => ({ text: c, value: c })),
      yesButtonText: 'Save',
      noButtonText: 'Cancel',
      onConfirm: () => {
        this.$store.dispatch('addTagsToTagCollection', {
          collectionName: this.$store.getters.itemSelected,
          tagIds: this.$store.getters.selectedTags,
        })
        this.$store.dispatch('unselectAllTags')
        this.$store.dispatch('closeModal')
      },
    })
  }

  moveToCollectionPrompt() {
    this.$store.dispatch('showConfirm', {
      title: 'Move to collection',
      options: Object.keys(this.$store.getters.tagCollections)
        .filter((c) => c !== this.currentCollection)
        .map((c) => ({ text: c, value: c })),
      yesButtonText: 'Save',
      noButtonText: 'Cancel',
      onConfirm: () => {
        this.$store.dispatch('moveTagsBetweenTagCollections', {
          fromCollectionName: this.currentCollection,
          toCollectionName: this.$store.getters.itemSelected,
          tagIds: this.$store.getters.selectedTags,
        })
        this.$store.dispatch('unselectAllTags')
        this.$store.dispatch('closeModal')
      },
    })
  }

  deleteSelectedTagsPrompt() {
    this.$store.dispatch('showConfirm', {
      title: 'Delete selected tags?',
      description:
        'You are about to permanently delete the selected tags. The tags will be removed from all episodes and collections. Are you sure?',
      yesButtonText: 'Yes, delete these tags',
      noButtonText: 'No, keep these tags',
      onConfirm: () => {
        let count = this.selectedTags.length
        this.selectedTags.forEach(async (tagId: any, index: number) => {
          await this.delTag(tagId)
          count -= 1
          this.$store.commit('deleteTag', tagId)
          this.$store.dispatch('deleteTagId', tagId)
          this.$store.dispatch('deleteTagFromTagCollections', tagId)
          if (count === 0) {
            this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId])
          }
        })

        this.$store.dispatch('unselectAllTags')
        this.$store.dispatch('closeModal')
      },
    })
  }
}
