






























import { Vue, Component, Prop } from 'vue-property-decorator'
import { isValidTime } from '@/utils/validation'
@Component
export default class EditDurationModal extends Vue {
  @Prop(Boolean) locked!: boolean
  @Prop(Function) saveDuration!: any
  @Prop(Function) openDurationModal!: () => void
  @Prop(String) millis!: string

  addDuration() {
    if (this.isValidationForInput) {
      this.saveDuration()
    }
  }
  handleCloseButtonClicked() {
    this.openDurationModal()
  }
  get mills() {
    return this.millis
  }
  set mills(value: string) {
    this.$emit('setMillis', value)
  }
  get isValidationForInput() {
    if (isValidTime(this.mills)) {
      return true
    }
    return false
  }
}
