var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "w-100 br2 white pb4",
      class:
        _vm.$router.currentRoute.fullPath === "/tags"
          ? "bg-adori-light-gray ba b--adori-gray"
          : "",
    },
    [
      _vm.isTagsLoading || _vm.isTrackTagsLoading || _vm.isDelTagLoading
        ? _c(
            "div",
            { staticClass: "flex flex-wrap ml3 mt3" },
            _vm._l(_vm.loaders, function (_, index) {
              return _c("div", {
                key: index,
                staticClass:
                  "w-100 bb bw1 b--adori-gray flex justify-center items-center mr3 mb3 br2 skeleton",
                class: {
                  "landscape-size": _vm.orientation === "LANDSCAPE",
                  "square-size": _vm.orientation === "SQUARE",
                  "portrait-size": _vm.orientation === "PORTRAIT",
                  "bg-adori-gray":
                    _vm.$router.currentRoute.fullPath === "/tags",
                  "bg-adori-light-gray":
                    _vm.$router.currentRoute.fullPath !== "/tags",
                },
              })
            }),
            0
          )
        : !_vm.isTagsLoading && _vm.tagIds && _vm.tagIds.length > 0
        ? _c(
            "draggable",
            {
              staticClass: "flex flex-wrap ml3 mt3",
              attrs: { options: _vm.dragOptions, move: _vm.onMove },
              on: {
                start: function ($event) {
                  return _vm.onDragStart()
                },
                end: _vm.handleDroppedTag,
              },
              model: {
                value: _vm.allTagIds,
                callback: function ($$v) {
                  _vm.allTagIds = $$v
                },
                expression: "allTagIds",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 cb-shadow flex flex-column justify-center items-center mr3 mb3 br2 new-tag b--adori-gray ba noselect",
                  class: {
                    "landscape-size": _vm.orientation === "LANDSCAPE",
                    "square-size": _vm.orientation === "SQUARE",
                    "portrait-size ": _vm.orientation === "PORTRAIT",
                  },
                  on: {
                    click: function ($event) {
                      _vm.$permissions.isCreateTagShowAllowed("")
                        ? _vm.editTag()
                        : ""
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "nodrag",
                    attrs: {
                      src: require("@/assets/audiogram/template-active.svg"),
                    },
                  }),
                  _c("div", { staticClass: "text silver mt1" }, [
                    _vm._v("New Tag"),
                  ]),
                ]
              ),
              _vm._l(_vm.tagIds, function (tagId, i) {
                return _c(
                  "div",
                  {
                    key: tagId + "-" + i,
                    staticClass: "relative hide-child mr3 mb3",
                    class: {
                      "landscape-size": _vm.orientation === "LANDSCAPE",
                      "square-size": _vm.orientation === "SQUARE",
                      "portrait-size": _vm.orientation === "PORTRAIT",
                    },
                  },
                  [
                    _c("AdoriTag", {
                      staticClass: "size",
                      class: _vm.tagIsDraggable ? "drag-handle grab" : "",
                      attrs: {
                        tagId: tagId,
                        selectable: _vm.tagIsSelectable,
                        filter: _vm.orientation,
                        smallTag: "",
                        draggable: _vm.tagIsDraggable,
                        hasButtons: "",
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass:
                          "smaller flex flex-column justify-around absolute child",
                        staticStyle: { top: "-30px", right: "5px" },
                      },
                      [
                        (_vm.currentCollection !== "ALL_TAGS" &&
                          _vm.$permissions.isDeleteTagAllowed()) ||
                        (_vm.currentCollection === "TAGS_IN_TRACK" &&
                          _vm.$permissions.isDeleteTagShowAllowed(""))
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Remove tag" },
                                on: {
                                  click: function () {
                                    if (
                                      _vm.currentCollection === "TAGS_IN_TRACK"
                                    ) {
                                      _vm.removeFromTrack(tagId, i)
                                    } else {
                                      _vm.removeFromCollection(tagId)
                                    }
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-top pa1",
                                  },
                                  [_vm._v(" cancel ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.currentCollection !== "TAGS_IN_TRACK" &&
                        _vm.isDeleteTagInShowsAllowed(tagId)
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Delete tag" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteTag(tagId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-top pa1",
                                  },
                                  [_vm._v(" delete ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isEditTagInShowsAllowed(tagId)
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Edit tag" },
                                on: {
                                  click: function ($event) {
                                    return _vm.editTag(tagId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid pa1",
                                  },
                                  [_vm._v(" edit ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.$permissions.isEditTagAllowed()
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "Add to collection" },
                                on: {
                                  click: function ($event) {
                                    return _vm.addToCollection(tagId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid pa1",
                                  },
                                  [_vm._v(" library_add ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.$permissions.isEditTagAllowed()
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "link pointer ba b--adori-gray br-100 bg-dark-gray w2 h2 flex justify-center items-center btn-shadow mb2",
                                attrs: { title: "View tag in tracks" },
                                on: {
                                  click: function ($event) {
                                    return _vm.viewTagDetails(tagId)
                                  },
                                },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons light-gray f4 v-mid pa1",
                                  },
                                  [_vm._v(" visibility ")]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm.currentCollection === "TAGS_IN_TRACK" &&
                    _vm.$permissions.isEditTagShowAllowed("")
                      ? _c("BaseTagLocationPicker", {
                          staticClass:
                            "absolute smaller picker-position pa2 bg-black-90 w-100 child",
                          attrs: {
                            text: "Time in audio",
                            icon: "access_time",
                            offsetMillis:
                              _vm.currentFilteredTagsInTrack[i].offsetMillis,
                          },
                          on: {
                            increment: function ($event) {
                              return _vm.incrementTagPosition(i, $event)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      !_vm.isTagsLoading &&
      _vm.tagIds &&
      _vm.tagIds.length === 0 &&
      _vm.tagSearchValue
        ? _c("div", { staticClass: "mh4 mt5 mb5 light-gray tc f4" }, [
            _vm._v(
              "\n    No tag results found! Try searching with a complete word.\n  "
            ),
          ])
        : !_vm.isTagsLoading && _vm.tagIds && _vm.tagIds.length === 0
        ? _c("div", [
            _c("div", { staticClass: "empty" }, [
              _c("img", {
                attrs: {
                  width: "150px",
                  alt: "placeholder",
                  src: require("@/assets/audiogram/no-data.svg"),
                },
              }),
              _c("div", { staticClass: "text white" }, [
                _vm._v("\n        You've not added any\n        "),
                _c("strong", [_vm._v(_vm._s(_vm.orientation))]),
                _vm._v("\n        tag yet\n      "),
              ]),
              _vm.currentCollection === "TAGS_IN_TRACK"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("span", [
                        _vm._v("Drag and drop tag from All my tags"),
                      ]),
                      _c("div", { staticClass: "mt1 mb2" }, [_vm._v("OR")]),
                      _c("BaseButtonRed", {
                        staticClass: "gram-btn",
                        attrs: {
                          text: "Auto create Tags using AI",
                          onClick: _vm.changeTab,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.currentCollection !== "TAGS_IN_TRACK"
                ? _c(
                    "div",
                    { staticClass: "flex flex-column items-center" },
                    [
                      _c("span", [_vm._v("Would you like to add one now?")]),
                      _c("BaseButtonRed", {
                        staticClass: "gram-btn",
                        attrs: { text: "Create Visual", onClick: _vm.editTag },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.hasNextTags &&
      _vm.currentCollection === "ALL_TAGS" &&
      _vm.tagIds &&
      _vm.tagIds.length !== 0
        ? _c(
            "div",
            { staticClass: "tc pb3 ph4 mt3" },
            [
              _c("BaseButtonLoadMore", {
                attrs: {
                  text: "Load more",
                  onClick: _vm.nextTagPage,
                  rootBottomMargin: "800",
                  onVisible: function () {},
                  loading: _vm.isNextTagFetching,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }