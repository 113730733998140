




























































































































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import draggable from 'vuedraggable'
import AdoriTag from '@/components/Tags/AdoriTag.vue'
import { getTimelinePosition } from '@/utils/time'
import { getQueryParam } from '@/utils/misc'
import { RESOURCES, ACTIONS } from '@/constants/permissions'
import { computed, ref, SetupContext } from '@vue/composition-api'
import { tagQueryKeys, useDeleteTag, useGetTags, useGetTrackTags } from '@/hooks/tag'
import { QueryClient, useQueryClient } from 'vue-query'
import mixpanel from 'mixpanel-browser'

// @ts-ignore
const webengage = window['webengage']

@Component({
  components: { AdoriTag, draggable },
  setup(props: any, { root }: SetupContext) {
    const queryClient = useQueryClient()
    const trackUid: any = getQueryParam('uid')
    const orientation = computed(() => props.orientation)
    const query = ref('')
    const isTrackEnabled = computed(() => !!trackUid)
    const { data: trackTags, isFetching: isTrackTagsLoading } = useGetTrackTags(trackUid, { enabled: isTrackEnabled })
    const {
      data: tagsData,
      fetchNextPage: nextTagPage,
      isFetchingNextPage: isNextTagFetching,
      isLoading: isTagsLoading,
      hasNextPage: hasNextTags,
    } = useGetTags({ orientation, query })

    const { mutateAsync: delTag, isLoading: isDelTagLoading } = useDeleteTag()

    return {
      tagsData,
      nextTagPage,
      isNextTagFetching,
      isTagsLoading,
      hasNextTags,
      trackTags,
      isTrackTagsLoading,
      query,
      queryClient,
      delTag,
      isDelTagLoading,
    }
  },
})
export default class TheTagsGrid extends Vue {
  @Prop(String) currentCollection!: string
  @Prop(String) orientation!: string
  @Prop(Array) tagsInTrack!: any[]
  @Prop(Boolean) showHeader!: boolean
  @Prop(Boolean) tagIsSelectable!: boolean
  @Prop(Boolean) tagIsDraggable!: boolean

  @Getter networkId!: any
  @Getter tagSearchValue!: any
  @Getter tag!: any
  @Getter ytModalId!: any
  @Getter selectedEpisodeSettings!: any
  @Getter timeLineZoom!: any

  @Action getTagIds!: any

  draggedTagPosition: any = {}
  currentWindowY: number = 0
  currentClientY: number = 0

  currentFilteredTagsInTrack: any = []

  tagsData!: any
  hasNextTags!: any
  nextTagPage!: any
  isNextTagFetching!: any
  isTagsLoading!: any
  trackTags!: any
  isTrackTagsLoading!: boolean
  query!: string
  delTag!: any
  isDelTagLoading!: boolean

  queryClient!: QueryClient

  onDragStart() {
    // this.scrollToTop()
    this.$emit('drag-started')
  }

  scrollToTop() {
    let timer
    let y = window.scrollY > 100 ? Math.floor(0.8 * window.scrollY) : 0
    window.scrollTo(0, y)

    if (y > 0) {
      timer = setTimeout(this.scrollToTop, 10)
    } else {
      clearTimeout(timer)
    }
  }

  mousePositionHandler(event: any) {
    if (event.type === 'touchmove') {
      this.currentWindowY = window.scrollY
      this.currentClientY = event.touches[0].clientY
    } else {
      this.currentWindowY = window.scrollY
      this.currentClientY = event.clientY
    }
  }

  onMove(e: any, ev: any) {
    document.addEventListener('mousemove', this.mousePositionHandler)
    document.addEventListener('touchmove', this.mousePositionHandler)
    this.draggedTagPosition = {
      id: crypto.randomUUID(),
      tagId: e.draggedContext.element,
      offsetMillis: getTimelinePosition(ev.clientX, this.timeLineZoom),
    }
    return false
  }

  handleDroppedTag() {
    document.removeEventListener('mousemove', this.mousePositionHandler)
    document.removeEventListener('touchmove', this.mousePositionHandler)
    let theSum = this.currentWindowY + this.currentClientY
    const at: any = document.getElementById('audioTaggerEl')
    let boundBox = at.getBoundingClientRect()

    if (this.currentClientY < boundBox.bottom && theSum !== 0 && this.currentClientY > boundBox.top) {
      mixpanel.track('Add Tag')
      this.$gtag.event('drag-drop-tag', { method: 'dropped timeline' })
      webengage?.track('drag-drop-tag', { position: this.draggedTagPosition })
      this.$emit('place-tag', this.draggedTagPosition)
    }
    this.currentWindowY = 0
    this.currentClientY = 0
  }

  editTag(tagId?: string) {
    this.$store.dispatch('newTagOpenFrom', this.orientation)
    this.$store.dispatch('showTagEdit', tagId)
  }

  changeTab() {
    this.$emit('change-tab')
  }

  deleteTag(tagId: string) {
    this.$store.dispatch('showConfirm', {
      title: 'Delete tag?',
      description: 'You are about to permanently delete the selected tag. Are you sure?',
      yesButtonText: 'Yes, delete this tag',
      noButtonText: 'No, keep this tag',
      onConfirm: async () => {
        await this.$store.dispatch('closeModal')
        //check if tag is present on timeline
        if (this.tagsInTrack) {
          const tagsInTrack = this.tagsInTrack.filter((tag: any) => tag.tagId !== tagId)
          this.$store.commit('setAudioTags', { audioUid: getQueryParam('uid'), tagPositions: tagsInTrack })
          await this.$store.dispatch('uploadAudioTags', getQueryParam('uid'))
        }
        await this.delTag(tagId)
        this.$store.commit('deleteTag', tagId)
        this.$store.dispatch('deleteTagId', tagId)
        this.$store.dispatch('deleteTagFromTagCollections', tagId)
        this.queryClient.invalidateQueries([tagQueryKeys.GET_TAGS, this.networkId])
      },
    })
  }

  addToCollection(tagId: string) {
    this.$store.dispatch('showConfirm', {
      title: 'Add to collection',
      options: Object.keys(this.$store.getters.tagCollections)
        .filter((c) => c !== this.currentCollection)
        .map((c) => ({ text: c, value: c })),
      yesButtonText: 'Save',
      noButtonText: 'Cancel',
      onConfirm: () => {
        this.$store.dispatch('addTagsToTagCollection', {
          collectionName: this.$store.getters.itemSelected,
          tagIds: [tagId],
          orientation: this.orientation,
        })
        this.$store.dispatch('closeModal')
      },
    })
  }

  viewTagDetails(tagId: string) {
    this.$store.dispatch('showShowsEpisodesTagModal', tagId)
  }

  removeFromCollection(tagId: string) {
    this.$store.dispatch('removeTagsFromTagCollection', {
      collectionName: this.currentCollection,
      tagIds: [tagId],
    })
  }

  removeFromTrack(tagId: string, index: number) {
    const selectedTag = this.currentFilteredTagsInTrack[index]
    const currentIdx = this.tagsInTrack.findIndex((tag: any) => tag.id === selectedTag.id)
    this.removeFromTrackByIndex(currentIdx)
  }

  removeFromTrackByIndex(tagIndex: number) {
    const audioUid = getQueryParam('uid')

    this.$store.dispatch('removeAudioTag', {
      audioUid,
      tagPosition: this.tagsInTrack[tagIndex],
    })
  }

  incrementTagPosition(index: number, sign: number) {
    const offset = this.currentFilteredTagsInTrack[index].offsetMillis
    const tagIndex = this.tagsInTrack.findIndex((tag: any) => offset === tag.offsetMillis)
    const audioUid = getQueryParam('uid')

    this.$store.dispatch('moveAudioTag', {
      index: tagIndex,
      audioUid,
      toPosition: offset + sign * 3000,
    })
  }

  get loaders() {
    return new Array(50)
  }

  get allTagIds() {
    // @ts-ignore
    return ['id', ...this.tagIds]
  }

  get tagIds() {
    if (this.currentCollection === 'TAGS_IN_TRACK') {
      this.currentFilteredTagsInTrack = []
      return this.ytModalId && this.selectedEpisodeSettings[this.ytModalId]
        ? this.tagsInTrack.reduce((acc: any, tag: any) => {
            const offsetMillis = tag.offsetMillis / 1000
            if (
              offsetMillis >= this.selectedEpisodeSettings[this.ytModalId].startTimeSec &&
              offsetMillis <= this.selectedEpisodeSettings[this.ytModalId].endTimeSec &&
              tag.tag.orientation === this.orientation
            ) {
              acc.push(tag.tagId)
              this.currentFilteredTagsInTrack.push(tag)
            }
            return acc
          }, [])
        : this.tagsInTrack &&
            this.tagsInTrack.reduce((acc: any, tag: any) => {
              if (tag.tag.orientation === this.orientation) {
                acc.push(tag.tagId)
                this.currentFilteredTagsInTrack.push(tag)
              }
              return acc
            }, [])
    }
    if (this.currentCollection === 'ALL_TAGS') {
      return this.tagsData.map((tag: any) => tag.id)
    }
    if (this.currentCollection) {
      return this.$store.getters.tagCollections[this.currentCollection].reduce((acc: any, id: string) => {
        const tag = this.$store.getters.tag(id) || this.$store.getters.allTagsById[id]
        tag && tag.orientation === this.orientation && acc.push(id)
        return acc
      }, [])
    }
    return []
  }

  get dragOptions() {
    return {
      group: {
        name: 'tagsGrid',
        pull: 'clone',
        put: false,
      },
      handle: '.drag-handle',
      forceFallback: true,
      sort: false,
      disabled: this.$router.currentRoute.fullPath === '/tags',
    }
  }

  // Todo: Come back when tags-search count is fixed from backend (load-more button)
  @Watch('tagSearchValue')
  async onTagSearchValueChanged() {
    this.query = this.tagSearchValue
  }

  @Watch('currentCollection')
  onCurrentCollectionChanged() {}

  isDeleteTagInShowsAllowed(tagId: String) {
    const tag = this.tag(tagId)
    const feedUids = tag && tag.feedUids
    return this.$permissions.validateNetworkShowPermissionsForShows(feedUids, RESOURCES.tag, ACTIONS.delete)
  }

  isEditTagInShowsAllowed(tagId: String) {
    const tag = this.tag(tagId)
    const feedUids = tag && tag.feedUids
    return this.$permissions.validateNetworkShowPermissionsForShows(feedUids, RESOURCES.tag, ACTIONS.edit)
  }
}
